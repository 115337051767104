export default [
  {
    name: 'About me',
    to: 'home',
    show: true
  },
  {
    name: 'Work history',
    to: 'history',
    show: true
  },
  {
    name: 'Certifications',
    to: 'cert',
    show: false
  },
  {
    name: 'Academy', // Training
    to: 'academy',
    show: true
  },
  {
    name: 'Book',
    to: 'book',
    show: true
  },
  {
    name: 'Blog',
    to: 'blog',
    show: true
  },
  {
    name: 'Pets',
    to: 'pets',
    show: true
  },
]
