<template>
  <RouterLink
    v-if="show"
    :to="{name: to}"
    :class="{'ra-menu-active': active}"
    class="ra-menu-link"
    v-text="name"
  />
</template>

<script>
export default {
  name: 'RaMenuItem',

  props: {
    name: {
      type: String,
      default: () => 'About me'
    },
    to: {
      type: String,
      default: () => 'home'
    },
    active: {
      type: Boolean
    },
    show: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/scss/variables.scss';

  .ra-menu-link {
    position: relative;
    font-size: 24px;
    padding: 0.1em 0.5em;
    text-decoration: none;
    color: $primaryColor;
    transition: color 0.2s;
    margin: 10px;
    outline: none;
    line-height: 1.8;
    white-space: nowrap;
    text-decoration: none;
    outline: none;
    font-family: sans-serif;
  }

  .ra-menu-link::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 0.08em;
    bottom: 0;
    left: 0;
    background-color: $primaryColor;
    z-index: -1;
    border-radius: 3px;
    transition: height 0.2s, background-color 0.2s;
  }

  .ra-menu-link:hover,
  .ra-menu-link:focus {
    color: $black;
  }

  .ra-menu-link:hover::before,
  .ra-menu-link:focus::before {
    height: 100%;
  }

  .ra-menu-active {
    opacity: 0.5;
  }

  .ra-menu-active:hover,
  .ra-menu-active:focus {
    color: $primaryColor;
  }

  .ra-menu-active:hover::before,
  .ra-menu-active:focus::before {
    height: 0.08em;
  }

  .ra-body-light {
    .ra-menu-link {
      color: $black;
    }
    .ra-menu-link:hover,
    .ra-menu-link:focus {
      color: $white;
    }
    .ra-menu-active:hover,
    .ra-menu-active:focus {
      color: $black;
    }
    .ra-menu-link::before {
      background-color: $black;
    }
  }
</style>
