import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueToast from 'vue-toast-notification'
import VueScrollProgressBar from '@guillaumebriday/vue-scroll-progress-bar'

import App from '@/App.vue'
import router from '@/router'

import 'v-title/lib/element-ui'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.config.productionTip = false

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(VueToast)

Vue.use(VueScrollProgressBar)

new Vue({
  name: 'RALMAZ',
  router,
  render: (h) => h(App),
}).$mount('#app')
