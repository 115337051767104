<template>
  <header class="ra-header ra-super">
    <h1 class="ra-logo-block">
      Roman
      <RouterLink
        v-if="false"
        :to="{name: 'alterego'}"
      >
        <img 
          v-title="'Alter ego'"
          src="@/assets/logos/ralmaz.png"
          class="ra-logo-ralmaz"
        >
      </RouterLink>
      Almazov
    </h1>
    <h1 class="ra-company-title-block">
      <span>
        Lead Frontend Engineer
      </span>
      <div v-if="false" class="ra-company-block">
        <div class="ra-company">
          <RaLink
            v-title="'I working in Atomic'"
            link="http://atomicwallet.io"
            follow
          >
            <img
              src="@/assets/logos/atomic_wallet_logo_black_vert.png"
              alt="Atomic"
              width="80"
              class="ra-atomic-logo-dark"
            >
            <img
              src="@/assets/logos/atomic_wallet_logo_white_vert_2.png"
              alt="Atomic"
              width="80"
              class="ra-atomic-logo-light"
            >
          </RaLink>
        </div>
      </div>
    </h1>
    <div class="ra-menu">
      <RaMenuItem
        v-for="(item, key) in menu"
        :key="key"
        :name="item.name"
        :to="item.to"
        :active="$route.name === item.to"
        :show="item.show"
      />
    </div>
    <NightToggle />
  </header>
</template>

<script>
import VTitle from 'v-title'

import RaLink from '@/components/elements/RaLink.vue'
import RaMenuItem from '@/components/elements/RaMenuItem.vue'
import NightToggle from '@/components/blocks/NightToggle.vue'

import menu from '@/data/menu'

export default {
  name: 'HeaderComponent',

  components: {
    RaMenuItem,
    RaLink,
    NightToggle
  },

  directives: {
    title: VTitle
  },

  data: () => ({
    menu: []
  }),

  mounted() {
    this.menu = menu
  }
}
</script>

<style lang="scss" scoped>
  .ra-company-block {
    display: grid;
    place-content: center;
  }

  .ra-company {
    margin-top: 35px;
    transition: all 0.4s;
    width: 100%;
    max-width: 300px;
  }

  .ra-company:hover {
    img {
      filter: hue-rotate(30deg);
    }
  }

  .ra-menu {
    max-width: 900px;
    margin: 0 auto;
  }

  .ra-logo-ralmaz {
    border-radius: 80%;
    width: 80px;
    height: 80px;
    margin-bottom: -16px;
  }

  .ra-company-title-block {
    margin-top: 0;
  }

  .ra-logo-block {
    margin-bottom: 30px;
  }

  // .ra-body-dark .ra-company {
  //   filter: invert(100%);
  // }
</style>
