<template>
  <footer class="ra-header ra-padding-bottom">
    <nav class="ra-demos">
      <span>Let's talk</span>
      <h3
        v-title="'Open in new tab'"
        class="ra-email"
        @click="openEmail(myEmail)"
        v-text="myEmail"
      />
    </nav>
    <nav class="ra-demos ra-social">
      <RaLink
        v-for="(item, key) in social"
        :key="key"
        v-title="item.name"
        :link="item.link"
        :class="item.class"
      >
        <img :src="item.image">
      </RaLink>
    </nav>
  </footer>
</template>

<script>
import VTitle from 'v-title'

import RaLink from '@/components/elements/RaLink.vue'

import social from '@/data/social'

export default {
  name: 'FooterComponent',

  components: {
    RaLink
  },

  directives: {
    title: VTitle
  },

  data: () => ({
    myEmail: 'me@ralmaz.pro',
    social: []
  }),

  mounted() {
    this.social = social
  },

  methods: {
    openEmail(email) {
      window.open(`mailto:${email}`, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/scss/variables.scss';

  .ra-social {
    margin: 0 auto;
    padding: 6px 2px;
    outline: none;
    letter-spacing: 1px;
    margin: 5px 10px;
    display: inline-block;
    img {
      padding: 5px;
      cursor: pointer;
      transition: border 0.2s;
      border: 10px double transparent;
      &:hover {
        border-left: 10px double $primaryColor;
      }
    }
    .ra-github img {
      &:hover {
        border-left: 10px double #293939 !important;
      }
    }
    .ra-telegram img {
      &:hover {
        border-left: 10px double #039BE5 !important;
      }
    }
    .ra-linkedin img {
      &:hover {
        border-left: 10px double #006EB2 !important;
      }
    }
    .ra-stackoverflow img {
      &:hover {
        border-left: 10px double #FBAF3F !important;
      }
    }
    .ra-twitch img {
      &:hover {
        border-left: 10px double #673AB7 !important;
      }
    }
    .ra-youtube img {
      &:hover {
        border-left: 10px double #FF0000 !important;
      }
    }
    .ra-spotify img {
      &:hover {
        border-left: 10px double #50B95D !important;
      }
    }
    .ra-steam img {
      &:hover {
        border-left: 10px double #212121 !important;
      }
    }
  }

  .ra-email {
    font-size: 30px;
    cursor: pointer;
    transition: color 0.2s;
    &:hover {
      color: $primaryColor;
      text-decoration: underline;
    }
  }

  .ra-body-light {
    .ra-social {
      img {
        &:hover {
          border-left: 10px double $black;
        }
      }
    }
    .ra-email {
      &:hover {
        color: $black;
        text-decoration: underline;
      }
    }
  }
</style>
