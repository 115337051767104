<template>
  <div
    v-cloak
    class="container"
  >
    <div class="content">
      <HeaderComponent />
      <RouterView />
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/blocks/Header.vue'
import FooterComponent from '@/components/blocks/Footer.vue'

export default {
  name: 'App',

  metaInfo: {
    title: 'Roman Almazov',
    titleTemplate: null,

    htmlAttrs: {
      lang: 'en'
    },

    meta: [
      { charset: 'UTF-8' },
      { httpEquiv: 'X-UA-Compatible', content: 'IE=edge' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
      { name: 'robots', content: 'index, follow' },
      { name: 'SKYPE_TOOLBAR', content: 'SKYPE_TOOLBAR_PARSER_COMPATIBLE' },
      { name: 'Googlebot', content: 'Noimageindex' },
      { name: 'yandex', content: 'all' },
      { name: 'imagemode', content: 'force' },
      { name: 'nightmode', content: 'disable' },
      { name: 'author', content: 'RALMAZ' },
      { name: 'og:site_name', content: 'RALMAZ' },
      { name: 'og:image', content: 'https://ralmaz.pro/preview.png' },
      { name: 'twitter:image', content: 'https://ralmaz.pro/preview.png' },
      { name: 'og:email', content: 'me@ralmaz.pro' }
    ]
  },

  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>

<style lang="scss">
  @import './scss/index.scss';
</style>
