import github from '@/assets/social/github.png'
import telegram from '@/assets/social/telegram.png'
import linkedin from '@/assets/social/linkedin.png'
// import stackoverflow from '@/assets/social/stackoverflow.png'
import twitch from '@/assets/social/twitch.png'
// import youtube from '@/assets/social/youtube.png'
// import spotify from '@/assets/social/spotify.png'
import steam from '@/assets/social/steam.png'

export default [
  {
    name: 'Github',
    link: 'https://github.com/RALMAZ',
    image: github,
    class: 'ra-github'
  },
  {
    name: 'Telegram',
    link: 'https://t.me/RALMAZ',
    image: telegram,
    class: 'ra-telegram'
  },
  {
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/in/roman-almazov-992483191/',
    image: linkedin,
    class: 'ra-linkedin'
  },
  /*
  {
    name: 'Stackoverflow',
    link: 'https://stackoverflow.com/users/13448819/ralmaz',
    image: stackoverflow,
    class: 'ra-stackoverflow'
  },
  */
  {
    name: 'Twitch',
    link: 'https://www.twitch.tv/ralmaz',
    image: twitch,
    class: 'ra-twitch'
  },
  /*{
    name: 'Youtube',
    link: 'https://www.youtube.com/channel/UCX1kOOdKjNxytkNBgObJkXg',
    image: youtube,
    class: 'ra-youtube'
  },
  {
    name: 'Spotify',
    link: 'https://open.spotify.com/user/ew1n24hdwitv2mqmm7kmjwfoy',
    image: spotify,
    class: 'ra-spotify'
  },*/
  {
    name: 'Steam',
    link: 'https://steamcommunity.com/id/ralmaz_vue',
    image: steam,
    class: 'ra-steam'
  }
]
