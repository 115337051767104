import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import(
  /* webpackChunkName: "home" */
  '@/views/Home.vue'
)

const WorkHistory = () => import(
  /* webpackChunkName: "work-history" */
  '@/views/WorkHistory.vue'
)

// const Certifications = () => import(
//   /* webpackChunkName: "certifications" */
//   '@/views/Certifications.vue'
// )

const Academy = () => import(
  /* webpackChunkName: "academy" */
  '@/views/Academy.vue'
)

const Book = () => import(
  /* webpackChunkName: "book" */
  '@/views/Book.vue'
)

const Alterego = () => import(
  /* webpackChunkName: "alterego" */
  '@/views/Alterego.vue'
)

const Blog = () => import(
  /* webpackChunkName: "blog" */
  '@/views/Blog.vue'
)

const Pets = () => import(
  /* webpackChunkName: "pets" */
  '@/views/Pets.vue'
)

const NotFound = () => import(
  /* webpackChunkName: "not-found" */
  '@/views/NotFound.vue'
)

// ARTICLES
const DefaulProperty = () => import(
  /* webpackChunkName: "default-property" */
  '@/components/articles/DefaultProperty/DefaultProperty.vue'
)

const Ordering = () => import(
  /* webpackChunkName: "ordering" */
  '@/components/articles/Ordering/Ordering.vue'
)

const Watcher = () => import(
  /* webpackChunkName: "watcher" */
  '@/components/articles/Watcher/Watcher.vue'
)

const Mixins = () => import(
  /* webpackChunkName: "mixins" */
  '@/components/articles/Mixins/Mixins.vue'
)

const Tailwind = () => import(
  /* webpackChunkName: "tailwind" */
  '@/components/articles/Tailwind/Tailwind.vue'
)

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/work-history',
    name: 'history',
    component: WorkHistory
  },
  // {
  //   path: '/cert',
  //   name: 'cert',
  //   component: Certifications
  // },
  {
    path: '/academy',
    alias: '/training',
    name: 'academy',
    component: Academy
  },
  {
    path: '/book',
    name: 'book',
    component: Book
  },
  {
    path: '/alter-ego',
    name: 'alterego',
    component: Alterego
  },
  
  {
    path: '/blog',
    name: 'blog',
    component: Blog
  },

  {
    path: '/pets',
    name: 'pets',
    component: Pets
  },

  {
    path: '/blog/default-property',
    name: 'default-property',
    component: DefaulProperty
  },
  {
    path: '/blog/ordering',
    name: 'ordering',
    component: Ordering
  },
  {
    path: '/blog/watcher',
    name: 'watcher',
    component: Watcher
  },
  {
    path: '/blog/mixins',
    name: 'mixins',
    component: Mixins
  },
  {
    path: '/blog/tailwind',
    name: 'tailwind',
    component: Tailwind
  },

  {
    path: '/not-found',
    alias: '*',
    name: '404',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  const elm = document.getElementsByClassName('ra-super')[0]
  if (elm) elm.scrollIntoView()
})

export default router
