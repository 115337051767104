<template>
  <div class="ra-night-toggle">
    <input
      id="night-toggle"
      v-model="isNight"
      type="checkbox"
      @change="proceedThemeChange"
    />
    <label for="night-toggle" />
    <VueScrollProgressBar
      height="4px"
      background-color="#ffc21a"
    />
  </div>
</template>

<script>
export default {
  name: 'NightToggle',

  data: () => ({
    isNight: false,
    total: 0
  }),

  mounted() {
    this.isNight = localStorage.getItem('ra-color') !== null
      ? localStorage.getItem('ra-color') === 'dark'
      : false
    this.proceedThemeChange(false)
  },

  methods: {
    proceedThemeChange(isToggle = true) {
      if (isToggle) {
        this.total += 1
        this.showIndicators()
      }
      if (this.isNight) {
        document.getElementsByTagName('body')[0].className = 'ra-body-dark'
        localStorage.setItem('ra-color', 'dark')
      } else {
        document.getElementsByTagName('body')[0].className = 'ra-body-light'
        localStorage.setItem('ra-color', 'light')
      }
    },

    showIndicators() {
      if (this.total === 5) {
        this.$toast.open({
          message: 'Really like this switch? :)',
          type: 'info'
        })
      }

      if (this.total === 15) {
        this.$toast.open({
          message: 'Looks like yes',
          type: 'info'
        })
      }

      if (this.total === 35) {
        this.$toast.open({
          message: 'WOOOOOOOOOOOO',
          type: 'info'
        })
      }

      if (this.total === 60) {
        this.$toast.open({
          message: 'What can I do for stop it?',
          type: 'warning'
        })
      }

      if (this.total === 100) {
        this.$toast.open({
          message: 'STOOOOOOOOOOOOOP',
          type: 'warning'
        })
      }

      if (this.total === 130) {
        this.$toast.open({
          message: 'You have a lot of free time as I see?',
          type: 'info'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/scss/variables.scss';

  .ra-night-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  label {
    position: relative;
    display: block;
    width: 90px;
    height: 50px;
    border-radius: 100px;
    background-color: $black;
    overflow: hidden;
    cursor: pointer;
    &:before,
    &:after {
      display: block;
      position: absolute;
      content: "";
      width: 36px;
      height: 36px;
      border-radius: 50%;
      top: 7px;
      left: 7px;
      transition: .5s ease;
    }
    &:before {
      background-color: $white;
    }

    &:after {
      background-color: $black;
      left: -29px;
      transform: scale(0.00001);
    }
  }

  input[type="checkbox"] {
    display: none;
    &:checked + label {
      &:before {
        background-color: $primaryColor;
        transform: translateX(40px);
      }
      &:after {
        transform: translateX(60px) scale(1);
      }
    }
  }
</style>
