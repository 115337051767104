<template>
  <a
    :href="link"
    :target="target"
    :rel="follow ? '' : 'nofollow'"
    :class="{
      'ra-link': styled,
      'ra-link-no-wrap': noWrap
    }"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'RaLink',

  props: {
    link: {
      type: String,
      default: () => 'https://ralmaz.pro/'
    },

    follow: {
      type: Boolean
    },

    styled: {
      type: Boolean
    },

    noWrap: {
      type: Boolean
    },

    target: {
      type: String,
      default: () => '_blank'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/scss/variables.scss';

  .ra-link {
    position: relative;
    font-size: 24px;
    padding: 0.1em;
    text-decoration: none;
    color: $primaryColor;
    transition: color 0.2s;
    // margin: 10px;
    outline: none;
    line-height: 1.8;
    text-decoration: none;
    outline: none;
    font-family: sans-serif;
    white-space: nowrap;
  }

  .ra-link::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 0.08em;
    bottom: 0;
    left: 0;
    background-color: $primaryColor;
    z-index: -1;
    border-radius: 3px;
    transition: height 0.2s, background-color 0.2s;
  }

  .ra-link:hover,
  .ra-link:focus {
    color: $black;
  }

  .ra-link:hover::before,
  .ra-link:focus::before {
    height: 100%;

    @media (max-width: 768px) {
      height: 0%;
    }
  }

  .ra-link-no-wrap {
    white-space: pre-wrap;
    &::before {
      height: 0;
    }
  }

  .ra-body-light {
    .ra-link {
      color: $black;
    }
    .ra-link:hover,
    .ra-link:focus {
      color: $white;
    }
    .ra-link::before {
      background-color: $black;
    }
  }
</style>
